.address-slider {
    display: flex;
    justify-content: space-around;
    height: 45px;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 999px;
    box-sizing: border-box;
    font-size: var(--font-size-caption);
    user-select: none;
}

.address-slider label {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 999px;
    border: 2px solid #f5f5f5;
    transition: all 250ms ease-in-out;
}

.address-slider input[type="radio"]:checked + label {
    background-color: white;
    font-weight: 700;
    border: 2px solid #E5E5E5;
}

.address-slider input[type="radio"] {
    display: none;
}

.address-slider input[type="radio"]:disabled:checked + label {
    opacity: 0.5;
}