/* cropper */

.cropper-photo-section {
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 20px;
}
.cropper-photo-wrap {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
}
.cropper-photo-image {
    float: none;
    display: inline-block;
    width: 100%;
    max-width: 180px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    border: solid 1px var(--grey-300);
}
.cropper-photo-image img {
    display: inline-block;
    min-width: 180px;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.cropper-photo-initials {
    display: flex;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--secondary-light);
    color: var(--common-white);
    font-size: 6.6rem;
    font-weight: bold;
    text-transform: uppercase;
}
.cropper-trigger-button {
    position: absolute;
    bottom: 1px;
    left: 1px;
    display: block;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: rgba(42, 42, 42, 0.55);
    border: none;
    -webkit-transition: background 0.3s ease-in-out;
    transition: background 0.3s ease-in-out;
}
.cropper-trigger-button:hover {
    background-color: rgba(42, 42, 42, 1);
}
.cropper-trigger-button .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 50px;
    height: 50px;
    -webkit-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
}
.cropper-trigger-button .icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: 4.5rem;
    color: var(--common-white);
}
.cropper-trigger-button:hover .icon::before {
    color: var(--primary-main);
}
.cropper-delete-button {
    left: auto;
    right: 1px;
}
.cropper-delete-button .icon::before {
    font-size: 3.5rem;
}
.cropper-trigger-link {
    text-decoration: underline;
    cursor: pointer;
}
.cropper-trigger-link:hover {
    color: var(--primary-main);
}

/* cropper window */

.ui-cropper {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
}
.cropper-image  {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 15px;
    padding: 0;
}
.cropper-image img {
    max-height: 100%;
    width: auto;
}
.cropper-container {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-bottom: 40px;
}
.cropper-controls {
    padding: 10px;
    background-color: rgba(42, 42, 42, 0.55);
    text-align: center;
}
.cropper-control-item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 0 0 0 5px;
    padding: 0;
}
.cropper-control-item .icon {
    -webkit-transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.cropper-control-item .icon:hover {
    background-color: var(--grey-a900);
}
.cropper-control-item .icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    font-size: var(--font-size-icon2);
    color: var(--common-white);
}
.cropper-control-item .icon:hover::before {
    color: var(--primary-main);
}
.cropper-control-item:last-of-type {
    margin-right: 0;
}
.cropper-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    padding: 0;
}
.cropper-sel.cropper-wrap {
    margin-right: 20px;
}
.cropper-sav.cropper-wrap {
    margin-left: 20px;
}
.cropper-button {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 10px 15px;
    font-size: var(--font-size-caption);
    font-weight: bold;
    color: var(--common-black);
    background-color: var(--common-white);
    border: solid 2px var(--grey-300);
    border-radius: 20px;
}
.cropper-sel .cropper-button {
    position: relative;
    z-index: 100;
    cursor: pointer;
}
.cropper-sel:hover .cropper-button,
.cropper-sav .cropper-button:hover {
    box-shadow: 0 3px 8px 0 rgba(51,51,51,0.1);
    transform: scale(1.01);
}
.cropper-sel input {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-size: 0;
    cursor: pointer;
    border-radius: 20px;
}