.upline-contacts-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
}

.upline-contacts-module__list {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
}

.upline-contacts-module__item {
    padding: 0 10px 0 10px;
}

.upline-contacts-module__item-mobile {
    flex: 0 0 34%;
}

.upline-contacts-module__personal-icon {
    width: 80px;
    height: 80px;
    opacity: 1;
    align-items: center;
    align-self: start;
    background-color: var(--grey-100);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    color: var(--grey-300);
    display: flex;
    flex-shrink: 0;
    font-size: var(--font-size-icon9);
    font-weight: 700;
    grid-column-end: profile-picture;
    grid-column-start: profile-picture;
    grid-row-end: profile-picture;
    grid-row-start: profile-picture;
    justify-content: center;
    line-height: 0.75;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    user-select: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
}

.upline-contacts-module__social {
    line-height: 36px;
}

.upline-contacts-module__phone,
.upline-contacts-module__email {
    display: inline-block;
    font-size: 5.2rem;
}

.upline-contacts-module__text,
.upline-contacts-module__name,
.upline-contacts-module__position {
    font-size: var(--font-size-body1);
    line-height: 1.21;
    color: var(--common-black);
}

.upline-contacts-module__text {
    max-width: 300px;
    margin-bottom: 15px;
}

.upline-contacts-module__name {
    margin-bottom: 5px;
    font-weight: bold;
    max-width: 99%;
}

.upline-contacts-module__position {
    margin-bottom: 15px;
}

.upline-contacts-module__avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.upline-contacts-module__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.upline-contacts-module__no-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    line-height: 1.56;
    border-radius: 50%;
    background-color: var(--secondary-light);
    color: var(--common-white);
    text-transform: uppercase;
    font-weight: bold;
}

.upline-contacts-module__social {
    line-height: 36px;
}

.upline-contacts-module__phone,
.upline-contacts-module__email {
    display: inline-block;
    font-size: 5.2rem;
}

.upline-contacts-module__phone {
    margin-right: 15px;
}

.upline-contacts-module__phone:hover,
.upline-contacts-module__email:hover,
.upline-contacts-module__email:focus,
.upline-contacts-module__phone:focus {
    color: var(--text-primary);
}

@media (min-width: 768px) {
    .upline-contacts-module {
        margin-top: 1px;
    }
    .upline-contacts-module:before {
        top: -43px;
    }
}