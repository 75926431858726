.loyalty-points-medal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    color: var(--common-white);
    border-radius: 50%;
    font-size: var(--font-size-body1);
    font-weight: bold;
    background-color: var(--warning-main);
}

.ui-product-box .loyalty-points-medal {
    position: absolute;
    top: 10px;
    left: 10px;
}

.ui-product-box.locked .loyalty-points-medal {
    background-color: var(--grey-200);
}

.minibag-reward-shop::before,
.minibag-reward-shop__points {
    display: inline-flex;
    border-radius: 50%;
    max-width: 38px;
    flex: 0 0 38px;
    height: 38px;
    justify-content: center;
    align-items: center;
    background: var(--warning-main);
    margin-right: 15px;
    color: var(--common-white);
}

.minibag-reward-shop::before {
    content: attr(data-reward-shop-points-remaining);
}

.minibag-reward-shop__points {
    background: transparent;
    position: absolute;
    left: 0;
    width: 38px;
}

.product-medal {
    position: absolute;
    left: 5%;
    top: 0;
    z-index: 10;
}

.ui-quick-shop .loyalty-points-medal {
    margin: 25px 0 0 0;
}

/* BP1 */
@media screen and (max-width: 479px) {
    .product-medal {
        top: 15px;
    }
}

/* BP3+ */
@media screen and (min-width: 768px) {
    .ui-new-product-detail .product-medal {
        left: calc(60px + 5%);
    }
}