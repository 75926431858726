.mobile-apps-module {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;        
}

.mobile-apps-module__list {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
    row-gap: 25px;
}

.mobile-apps-module__item {
    padding: 0 10px 0 10px;
}

.mobile-apps-module__item-mobile {
    flex: 0 0 34%;
}

.mobile-apps-module__app-icon {
    width: 70px;
    height: 70px;
    opacity: 1;
    align-items: center;
    align-self: start;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    color: rgb(255, 255, 255);
    display: flex;
    flex-shrink: 0;
    font-size: var(--font-size-icon8);
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    user-select: none;
    -webkit-box-align: center;
    -webkit-box-pack: center;
}

.mobile-apps-module__download {
    line-height: 36px;
}

.mobile-apps-module__android,
.mobile-apps-module__apple {
    display: inline-block;    
    max-width: 100px;
}

.mobile-apps-module__name{
    font-size: var(--font-size-body1);
    line-height: 1.21;
    color: var(--common-black);
}

.mobile-apps-module__name {
    margin-bottom: 15px;
    font-weight: bold;
    max-width: 99%;
    min-width: 124px;
}

.mobile-apps-module__logo {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.mobile-apps-module__android {
    margin-right: 2px;
}

@media (min-width: 768px) {
    .mobile-apps-module {
        margin-top: 1px;
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-apps-module:before {
        top: -43px;
    }    
    .mobile-apps-module__list {
        row-gap: 40px;
    }
}