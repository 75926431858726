.file-download-module {
    position: relative;
    height: 75px;
    text-align: center;
    text-transform: uppercase;
    font-size: var(--font-size-caption);
}

@media (min-width: 700px) {
    .file-download-module {
        height: 95px;
    }
}

.file-download-module a:hover,
.file-download-module a:active,
.file-download-module a:focus {
    color: var(--text-primary);
}

.file-download-module__link-icon {
    display: inline-block;
    font-size: 6.8rem;
    line-height: 0.69;
}

    .file-download-module__link-icon.hover {
        font-size: 7.4rem;
    }


@media (min-width: 700px) {
    .file-download-module__link-icon {
        font-size: 9.8rem;
        line-height: 0.68;
    }

        .file-download-module__link-icon.hover {
            font-size: 10.4rem;
        }
}

.file-download-module__icon {
    display: inline-block;
}

.file-download-module__link {
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
}

@media (min-width: 700px) {
    .file-download-module__link {
        top: 80px;
    }
}

.file-download-module__link-text {
    padding-top: 10px;
}

.file-download-module__link-text.hover {
    font-weight: bold;
}