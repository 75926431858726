.my-motivation {
    text-align: center;
    margin: 15px auto;
    color: var(--text-primary);
}

.my-motivation-textarea {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: 15px;
}

@media (min-width: 700px) {
    .my-motivation-textarea {
        margin: 15px auto;
        max-width: 578px;
    }
}

.my-motivation-edit-text {
    font-weight: bold;
    font-size: var(--font-size-caption);
    margin-bottom: 15px;
}

.my-motivation-edit-text {
    font-weight: bold;
    font-size: var(--font-size-caption);
    margin-bottom: 15px;
}

.my-motivation-button, .my-motivation-button-icon {
    display: block;
    width: auto;
    min-width: 330px;
    max-width: 100%;
    margin: 0 auto;
}

.my-motivation-button-icon {
    font-size: var(--font-size-icon8);
}

.my-motivation-button.k-button .k-loading-image {
    margin-top: -17px;
    right: 34px;
}

.my-motivation-button.k-button .k-loading-image:before {
    width: 34px;
    height: 34px;
}

