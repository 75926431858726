.article-top-image {
    margin: 45px 0;
}

@media (min-width: 700px) {
    .article-top-image {
        margin: 60px;
    }
}

.article-top-image__title {
    margin: 0 15px 15px 15px;
    max-width: 670px;
    font-size: var(--font-size-h5);
    line-height: 1.25;
    text-align: center;
    color: var(--grey-700);
}

@media (min-width: 700px) {
    .article-top-image__title {
        margin: 0 auto 15px;
        font-size: var(--font-size-h4);
        line-height: 1.2;
    }
}

.article-top-image__intro {
    margin: 0 15px 15px 15px;
    text-align: center;
    max-width: 670px;
}

@media (min-width: 700px) {
    .article-top-image__intro {
        margin: 0 auto 30px;
    }
}

.article-top-image__figure {
    margin: 15px auto 0;
    max-width: 670px;
}

@media (min-width: 700px) {
    .article-top-image__figure {
        margin: 0 auto 45px;
    }
}

.article-top-image__teasers {
    margin: 45px 15px 0 15px;
    display: grid;
    grid-gap: 45px 60px;
    justify-items: center;
}

@media (min-width: 700px) {
    .article-top-image__teasers {
        margin: 0;
        text-align: center;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

.article-top-image > :last-child {
    margin-bottom: 0;
}