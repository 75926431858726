.article-sub-image {
    text-align: center;
    max-width: 260px;
}

.article-sub-image__title {
    color: var(--grey-700);
    font-size: var(--font-size-body1);
    line-height: 1.43;
    text-align: center;
}

@media (min-width: 700px) {
    .article-sub-image__title {
        font-size: var(--font-size-h5);
        line-height: 1.25;
    }
}

.article-sub-image-figure {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.article-sub-image__text {
    text-align: center;
}

.article-sub-image__figure,
.article-sub-image__title {
    margin-bottom: 10px;
}
