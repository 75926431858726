/* #region end consumer */
.end-consumer-editor-container .w-buttons .k-button {
    margin: 0 0 15px;
}

@media screen and (min-width: 768px) {
    .end-consumer-editor-container .w-buttons .k-button {
        margin: 0 0 0 10px;
    }
}
