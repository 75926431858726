.article-rich-text {
    font-size: var(--font-size-body1);
    line-height: 1.4;
    text-align: left;
    overflow: hidden;
}

.article-rich-text p {
    margin: 0 0 20px 0;
    font-size: var(--font-size-body1);
    line-height: 1.36;
}

.article-rich-text h1 {
    margin: 0;
    font-size: var(--font-size-h1);
    line-height: 1.17;
    color: var(--grey-700);
}

.article-rich-text h2 {
    margin: 0;
    font-size: var(--font-size-h4);
    line-height: 1.2;
    color: var(--grey-700);
}

.article-rich-text h3 {
    margin: 0;
    font-size: var(--font-size-h5);
    line-height: 1.25;
    color: var(--grey-700);
}

.article-rich-text h4 {
    margin: 0;
    font-size: var(--font-size-h5);
    line-height: 1.25;
    color: var(--grey-700);
}

.article-rich-text h5 {
    margin: 0;
    font-size: var(--font-size-body1);
    line-height: 1.43;
    color: var(--grey-700);
}

@media (min-width: 700px) {
    .article-rich-text h1 {
        margin: 0;
        font-size: var(--font-size-heading1);
        line-height: 1.14;
    }

    .article-rich-text h2 {
        margin: 0;
        font-size: var(--font-size-h2);
        line-height: 1.17;
    }

    .article-rich-text h3 {
        margin: 0;
        font-size: var(--font-size-h3);
        line-height: 1;
    }

    .article-rich-text h4 {
        margin: 0;
        font-size: var(--font-size-h5);
        line-height: 1.25;
    }

    .article-rich-text h5 {
        margin: 0;
        font-size: var(--font-size-body1);
        line-height: 1.43;
    }
}

.article-rich-text ul,
.article-rich-text ol {
    margin-left: 10px;
    margin-bottom: 15px; /* added to follow logic of p element */
    padding-left: 10px;
}

.article-rich-text p a {
    text-decoration: underline;
}

.article-rich-text img {
    margin-top: 16px;
}
