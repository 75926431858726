.my-interest {
    text-align: center;
    margin: 15px auto;
    color: var(--text-primary);
}

.my-interest-heading {
    font-weight: bold;
    font-size: var(--font-size-h5);
}


@media (min-width: 700px) {
    .my-interest-heading {
        font-size: var(--font-size-h4);
    }
}

.my-interest-options {
    text-align: left;
    margin: 15px;
}

@media (min-width: 700px) {
    .my-interest-options {
        display: inline-block;
    }
}

.my-interest-edit-text {
    font-weight: bold;
    font-size: var(--font-size-caption);
    margin-bottom: 15px;
}

.my-interest-button, .my-interest-button-icon {
    display: block;
    width: auto;
    min-width: 330px;
    max-width: 100%;
    margin: 0 auto;
}

.my-interest-button-icon {
    font-size: var(--font-size-icon8);
}

.my-interest-button.k-button .k-loading-image {
    margin-top: -17px;
    right: 34px;
}

    .my-interest-button.k-button .k-loading-image:before {
        width: 34px;
        height: 34px;
    }
