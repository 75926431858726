.article-side-image {
    margin: 45px 0;
}

@media (min-width: 700px) {
    .article-side-image {
        margin: 45px 60px;
    }
}

.article-side-image__subtitle {
    margin: 0 0 5px 0;
    padding: 0 15px;
    color: var(--text-disabled);
    font-size: var(--font-size-body1);
    font-weight: 400;
    text-align: center;
}

@media (min-width: 700px) {
    .article-side-image__subtitle {
        padding: 0;
    }
}

.article-side-image__title {
    margin: 0 0 15px 0;
    padding: 0 15px;
    color: var(--grey-700);
    font-size: var(--font-size-h5);
    line-height: 1.25;
    text-align: center;
}

@media (min-width: 700px) {
    .article-side-image__title {
        margin: 0 0 30px 0;
        padding: 0;
        font-size: var(--font-size-h4);
        line-height: 1.2;
    }
}

.article-side-image__row {
    display: grid;
    grid-row-gap: 15px;
}

@media (min-width: 700px) {
    .article-side-image__row {
        grid-column-gap: 64px;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        word-break: break-word;
    }
}

.article-side-image__col--right {
    order: 1;
}

.article-side-image__col {
    display: grid;
    grid-row-gap: 30px;
    align-content: start;
}

.article-side-image__top-text,
.article-side-image__bottom-text {
    padding: 0 15px;
}

@media (min-width: 700px) {
    .article-side-image__top-text,
    .article-side-image__bottom-text {
        padding: 0;
    }
}

.article-side-image-figure-small {
    width: 80px;
    height: 80px;
    margin-right: 30px;
    flex-shrink: 0;
}

.article-side-image__teaser {
    display: flex;
    padding: 0 15px;
}

@media (min-width: 700px) {
    .article-side-image__teaser {
        padding: 0;
    }
}