/* UI Popup Lang Switcher */

.ui-popup-lang-switcher {
    margin: 0 auto;
    overflow: hidden;
    display: block;
    box-sizing: border-box;
}

.w-lang-country-select .ui-popup-lang-switcher {
    display: none;
}

.ui-popup-lang-switcher .headline {
    font-size: var(--font-size-h4);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: center;
}

.ui-popup-lang-switcher a {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.ui-popup-lang-switcher .intro-text {
    margin-bottom: 30px;
    line-height: 1.3em;
}

.ui-popup-lang-switcher .k-button {
    width: 47.5%; /* fallback */
    width: calc(50% - 11px);
    float: left;
    margin-right: 22px;
}

.ui-popup-lang-switcher .k-button:last-child {
    margin-right: 0;
}

/* BP2 */

@media screen and (min-width: 480px) and (max-width: 767px) {
    .ui-popup-lang-switcher {
        width: 350px;
    }
}

/* BP3 */

@media screen and (min-width: 768px) and (max-width: 919px) {
    .ui-popup-lang-switcher {
        width: 575px;
    }
}

/* Landing page specific CSS */
@media only screen and (max-width: 767px) {
	.ui-popup-lang-switcher {
		padding: 0 15px;
	}
	.ui-popup-lang-switcher .k-button {
		width: 100%;
		float: none;
		margin: 0 0 10px;
		text-transform: none;
	}
}