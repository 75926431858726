.page-editor.page-mypages .n-content figure,
.page-editor.page-support-center figure {
    border: 1px dashed transparent;
    border-radius: 2px;
    background: var(--grey-a200);
}

.page-editor.page-mypages .n-content div.scEnabledChrome,
.page-editor.page-support-center div.scEnabledChrome {
    border-radius: 2px;
    padding: 0 10px 20px 10px;
}

.page-editor--controls.page-mypages .n-content div.scEnabledChrome,
.page-editor.page-mypages .n-content div.scEnabledChrome:hover,
.page-editor.page-mypages .n-content div.sc-active-editable-item,
.page-editor--controls.page-support-center div.scEnabledChrome,
.page-editor.page-support-center div.scEnabledChrome:hover,
.page-editor.page-support-center div.sc-active-editable-item {
    outline: 2px solid #289BC8;
}

.page-editor.page-mypages .n-content span.scEnabledChrome,
.page-editor.page-support-center span.scEnabledChrome {
    border: 1px dashed transparent;
    border-radius: 2px;
}

.page-editor--controls.page-mypages .n-content div.scEnabledChrome span.scEnabledChrome,
.page-editor.page-mypages .n-content div.scEnabledChrome:hover span.scEnabledChrome,
.page-editor--controls.page-mypages .n-content div.scEnabledChrome figure,
.page-editor.page-mypages .n-content div.scEnabledChrome:hover figure,
.page-editor--controls.page-mypages .n-content figure,
.page-editor.page-mypages .n-content figure:hover,
.page-editor--controls.page-mypages .n-content span.scEnabledChrome,
.page-editor.page-mypages .n-content span.scEnabledChrome:hover,

.page-editor--controls.page-support-center div.scEnabledChrome span.scEnabledChrome,
.page-editor.page-support-center div.scEnabledChrome:hover span.scEnabledChrome,
.page-editor--controls.page-support-center div.scEnabledChrome figure,
.page-editor.page-support-center div.scEnabledChrome:hover figure,
.page-editor--controls.page-support-center figure,
.page-editor.page-support-center figure:hover,
.page-editor--controls.page-support-center span.scEnabledChrome,
.page-editor.page-support-center span.scEnabledChrome:hover {
    border: 1px dashed var(--grey-a400);
}

.page-editor.page-mypages .n-content .sc-active-editable-item span.scEnabledChrome,
.page-editor.page-support-center .sc-active-editable-item span.scEnabledChrome {
    border: 1px dashed var(--grey-a400);
}

.page-editor.page-mypages .n-content .sc-active-editable-item,
.page-editor.page-support-center .sc-active-editable-item {
    border-color: #99DEFD !important;
    background: #d5effc70;
}

.page-editor.page-mypages .n-content img.sc-active-editable-item,
.page-editor.page-support-center img.sc-active-editable-item {
    opacity: 0.6;
    background: #ededed;
}

.page-editor.page-mypages .scWebEditInput.sc-active-editable-item[contentEditable="true"],
.page-editor.page-support-center .scWebEditInput.sc-active-editable-item[contentEditable="true"] {
    min-width: 3em;
}

.page-editor.page-mypages .n-content .scInsertionHandle--before,
.page-editor.page-support-center .scInsertionHandle--before {
    margin-top: -25px;
}

.page-editor.page-mypages .n-content .scInsertionHandle--after,
.page-editor.page-support-center .scInsertionHandle--after {
    margin-top: 22px;
}

.page-editor.page-mypages .scChromeToolbar,
.page-editor.page-support-center .scChromeToolbar {
    height: auto;
}